import { HttpClient } from '@angular/common/http';
import { computed, inject, Inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';
import { INews } from '@core/domain/news';
import { IList, IResponse, responseToListWithPagination, setList, totListWithPaginationState } from '@core/types';
import { LOADING_STATUS, LoadingStatus } from '@core/types/loading-status';
import { merge, Observable, shareReplay, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { envToken, IEnvironment } from '../environment';
import { ISettingsService, settingsServiceToken } from '../settings';
import { INewsService } from './news.service.domain';

@Injectable({providedIn: 'root'})
export class NewsService implements INewsService {
    private http: HttpClient = inject(HttpClient);

    private NEWS_API: string = 'tc/v1/news';

    private news$: Observable<IList<INews>> = this.getNewsList().pipe(shareReplay());

    public newsList: Signal<IList<INews>> = toSignal(this.news$, {initialValue: setList<INews>()});

    public readNewsIds: Signal<string[]> = computed(() => {
        return this.settingsService.settings().read_news || [];
    });

    public currentPage: WritableSignal<number> = signal(1);

    private loadingStatusAction: Subject<LoadingStatus> = new Subject<LoadingStatus>();

    private loadingStatus$: Observable<LoadingStatus> = merge(
        this.loadingStatusAction.pipe(map(() => LOADING_STATUS.LOADING)),
        this.settingsService.settings$.pipe(map(() => LOADING_STATUS.LOADED)),
    );

    public loadingStatus: Signal<LoadingStatus> = toSignal(this.loadingStatus$, {initialValue: LOADING_STATUS.LOADING});

    constructor(
        @Inject(settingsServiceToken) private settingsService: ISettingsService,
        @Inject(envToken) private env: IEnvironment,
    ) {
    }

    private getNewsList(): Observable<IList<INews>> {
        const body: Params = {channel_like: this.env.newsChannel};

        return this.http.request<IResponse<INews> | null>('GET', this.NEWS_API, {body}).pipe(
            map(responseToListWithPagination),
            totListWithPaginationState(),
        );
    }

    public markAsRead(newsId: string): void {
        this.loadingStatusAction.next(LOADING_STATUS.LOADING);
        this.settingsService.updateSettings({
            read_news: [...this.readNewsIds(), newsId],
        });
    }

    public markAsUnRead(newsId: string): void {
        this.loadingStatusAction.next(LOADING_STATUS.LOADING);
        this.settingsService.updateSettings({
            read_news: this.readNewsIds().filter((id: string) => id !== newsId),
        });
    }
}
