import { Routes } from '@angular/router';
import { permissionsGuard } from '@core/guards/permissions';
import { WorkspaceRoute } from '@core/types/workspace-route';

export const TEMPTON_CONNECT_ROUTES: Routes = [
    {
        path: '',
        // TODO: REMOVE AFTER workspace will be removed from url
        canActivate: [permissionsGuard],
        children: [
            {
                path: WorkspaceRoute.Dispatcher,
                loadChildren: () => import('./workspace/dispatcher/routes').then((r) => r.ROUTES),
            },

            {
                path: WorkspaceRoute.Customer,
                loadChildren: () => import('./workspace/customer/routes').then((r) => r.ROUTES),
            },

            {
                path: WorkspaceRoute.Prospect,
                loadChildren: () => import('./workspace/prospect/routes').then((r) => r.ROUTES),
            },
        ],
    },

    {
        path: '**',
        redirectTo: '',
    },
];
